module.exports = {
	en: {
		default: true,
		path: 'en',
		locale: 'en-us',
		siteLanguage: 'en',
		ogLang: 'en_US',
		dateFormat: `YYYY`,
	},
}
