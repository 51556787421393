module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":2400,"linkImagesToOriginal":false,"backgroundColor":"none","quality":88}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vitor Dino","short_name":"Vitor Dino","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"/opt/build/repo/src/assets/images/icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
